import React from "react"
import Layout from "../../component/Layout"
import css from "./contacts.module.css"

const Contacts = () => {
  return (
    <Layout>
      <div className={css.contacts}>
        <div>+380-67-3851218</div>
        <div>+380-66-7678088</div>
        <div>Skype: jein88</div>
        <div>zhenya.zharikova at gmail.com</div>
      </div>
    </Layout>
  )
}

export default Contacts
